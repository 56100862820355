import { GENERAL_ENV } from "./environments/general";
import { LOCAL_ENV } from "./environments/local";
import { T_ENV } from "./environments/t";
import { Q_ENV } from "./environments/q";
import { P_ENV } from "./environments/p";
import { IBfcKeycloakConfig } from "@bfl/components/authentication";
import { IBfcConfiguration } from "@bfl/components/configuration";

export const configuration: IBfcConfiguration[] = [
  {
    theme: "bkw",
    general: GENERAL_ENV,
    local: LOCAL_ENV,
    t: T_ENV,
    q: Q_ENV,
    p: P_ENV,
  },
];

export interface MeteringWorkforceAppConfiguration {
  apiBaseURL?: string;
  registrationUrl?: string;
  keycloakConfig?: IBfcKeycloakConfig;
  googleTagManagerContainerId?: string;
  myDashboardUrl?: string;
  maxDaysPostponeAppointment?: number;
}
