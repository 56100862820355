import { Component, HostBinding, OnInit } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {

  @HostBinding("class") classes = "bfc-base";

  private readonly WHITELISTED_PREFIX_URL: string = "metering-workforce";

  private readonly PARAMETER_NAME_NO_REDIRECT: string = "noRedirect";

  private isLocalhost: boolean = this.bfcConfigurationService.environment.stage === "local";

  constructor(private bfcTranslationService: BfcTranslationService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  ngOnInit(): void {
    const currentUrlString: string = window.location.href;
    let currentUrl: URL = new URL(currentUrlString);
    const redirectOk: boolean = !(currentUrl?.searchParams?.get(this.PARAMETER_NAME_NO_REDIRECT)?.length > 0);
    const userOrSessionLanguage = this.bfcTranslationService.language;

    const prefixUrlOfCurrentLanguage = this.bfcTranslationService.translate("PREFIX_URL");

    if (redirectOk && !currentUrlString.includes(this.WHITELISTED_PREFIX_URL) && !this.isLocalhost &&
        !currentUrlString.includes(prefixUrlOfCurrentLanguage)) {
      // Go to URL: check prefixUrlOfChosenLanguage and change user language, if needed
      let targetLanguage = "de";
      if (userOrSessionLanguage.toLowerCase() === "de") {
        targetLanguage = "fr";
      }

      if (userOrSessionLanguage.toLowerCase() !== targetLanguage) {
        // add param
        currentUrl.searchParams.set(this.PARAMETER_NAME_NO_REDIRECT, "true");
        this.bfcTranslationService.changeLanguage(targetLanguage, currentUrl.toString());
      }
    }
  }

}
