import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IdentifyAuthGuard } from "./core/identify.auth-guard";
import { AppointmentAuthGuard } from "./core/appointment.auth-guard";

const routes: Routes = [
  {
    path: "appointments",
    loadChildren: () => import("./appointments/appointments.module").then(m => m.AppointmentsModule),
    canActivate: [AppointmentAuthGuard],
  },
  {
    path: "identify",
    loadChildren: () => import("./identify/identify.module").then(m => m.IdentifyModule),
    canActivate: [IdentifyAuthGuard],
  },
  {
    path: "",
    redirectTo: "appointments",
    pathMatch: "full",
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
