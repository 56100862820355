export const DE_TRANSLATIONS = {
  "PREFIX_URL": "monteurtermin",
  "SERVICE_NAME": "Terminübersicht",
  "IDENTIFY": {
    "TITLE": "Termin Zählerwechsel",
    "TEXT": "Geben Sie den 12-stelligen Zugangscode ein, den Sie per Post oder E-Mail erhalten haben, um Ihren persönlichen Zählermonteur Termin zu bearbeiten. Beachten Sie die Gross- und Kleinschreibung.",
    "IDENTIFY": "Freischalten",
    "CODE_REQUIRED": "Bitte geben sie einen gültigen Code ein.",
    "PLACEHOLDER": {
      "CODE": "12-stelliger Code",
    },
  },
  "DATES": {
    "HOURS": "Uhr",

    "SUNDAY": "Sonntag",
    "MONDAY": "Montag",
    "TUESDAY": "Dienstag",
    "WEDNESDAY": "Mittwoch",
    "THURSDAY": "Donnerstag",
    "FRIDAY": "Freitag",
    "SATURDAY": "Samstag",

    "JANUARY": "Januar",
    "FEBRUARY": "Februar",
    "MARCH": "März",
    "APRIL": "April",
    "MAY": "Mai",
    "JUNE": "Juni",
    "JULY": "Juli",
    "AUGUST": "August",
    "SEPTEMBER": "September",
    "OCTOBER": "Oktober",
    "NOVEMBER": "November",
    "DECEMBER": "Dezember",

    "MORNING": "Vormittag",
    "AFTERNOON": "Nachmittag",
    "EVENING": "Abend",
  },
  "BKWCAB_MAIN": {
    "ERR_INVALID_AUTH": "Keine Authentifizierungsdaten verfügbar",
    "ERR_NO_ORDER_INFOS": "Keine Termine verfügbar",
    "BACK_TO_OVERVIEW": "Zurück zur Terminübersicht",
    "BACK_TO_DASHBOARD": "Zurück zur Übersicht",
    "BACK_TO_DATES": "Zurück zur Terminauswahl",
    "BACK_TO_ENTERCODE": "Zurück zur Codeeingabe",
    "NEW_APPOINTMENT_TITLE": "Termin Zählerwechsel",
    "ORDER_NUMBER": "Auftragsnummer",
    "PREPOSITION_TO": "bis",
    "LABEL_BTN_EDIT": "Termin bearbeiten",
    "LABEL_BTN_NEXT": "Weiter",
    "LABEL_BTN_PREVIOUS": "Zurück",
    "CONTACT_US_MESSAGE": "Bitte melden Sie sich unter der Telefonnummer, die in Ihrem Avisierungsschreiben angegeben ist. Das Avisierungsschreiben ist das Schreiben, in dem die Information zu Ihrem Termin und Ihr persönlicher Zugangscode enthalten ist.",
    "REQUIRED_FIELD_MESSAGE": "Dieses Feld wird benötigt",
    "INVALID_PHONE_MESSAGE": "Die Telefonnummer ist ungültig",
    "INVALID_EMAIL_MESSAGE": "Die E-Mail ist ungültig",
  },

  "ELECTRIC_METER": {
    "INTRO_CONTENT": "Der Zählermonteur benötigt Zugang zu Ihrem Stromzähler. Bitte teilen Sie uns via «Termin bearbeiten» Ihre Zugangs- und Kontaktinformationen mit.",
  },

  "STEPPER": {
    "STEP1_LABEL": "Zugangsinformation",
    "STEP2_LABEL": "Ihre Kontaktdaten",
    "STEP3_LABEL": "Abschluss",
    "QUESTION1": {
      "LABEL": "Können Sie den Termin bestätigen?",
      "RADIO1_CAPTION": "Ja, ich bestätige den Termin.",
      "RADIO2_CAPTION": "Nein, ich brauche einen neuen Termin.",
    },
    "SLOT_CHOICE": {
      "PLACEHOLDER": "Wählen Sie Ihr Wunschdatum.",
      "LABEL_MORE_DATES": "Weitere Termine",
    },
    "QUESTION2": {
      "LABEL": "Wie können wir zum Stromzähler gelangen?",
      "RADIO_CAPTION_ACCESSTYPE__10": "Ich bin vor Ort.",
      "RADIO_CAPTION_ACCESSTYPE__20": "Der Schlüssel ist hinterlegt.",
      "RADIO_CAPTION_ACCESSTYPE__30": "Der Schlüssel ist bei einer Person hinterlegt.",
      "RADIO_CAPTION_ACCESSTYPE__40": "SONSTIGES",
      "SUGGESTED_COMMENT_ACCESSTYPE__10": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__20": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__30": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__40": " ",
    },
    "STEP1_NO_SLOTS_MSG": "Aktuell ist es nicht möglich, einen anderen Termin zu buchen.",
    "ACCESS_GRANTED_BY_FORM": {
      "TEXT": "Zutritt wird gewährt durch:",
      "TXTFIRSTNAME": {
        "PLACEHOLDER": "Name",
      },
      "TXTLASTTNAME": {
        "PLACEHOLDER": "Vorname",
      },
      "TXTPHONE": {
        "PLACEHOLDER": "Mobilnummer",
      },
      "INFO_TEXT": "Der Monteur ruft auf diese Nummer an.",
    },
    "TXTCOMMENTS": {
      "PLACEHOLDER_ACCESSTYPE__": "Kommentar für den Monteur",
      "PLACEHOLDER_ACCESSTYPE__10": "Kommentar für den Monteur",
      "PLACEHOLDER_ACCESSTYPE__20": "Bitte lassen Sie uns wissen, wo der Schlüssel hinterlegt sein wird. *",
      "PLACEHOLDER_ACCESSTYPE__30": "Kommentar für den Monteur *",
    },
    "STEP1_SUMMARY": {
      "TEXT_NEW_APPOINTMENT": "Neuer Termin",
    },

    "STEP2_INTRO_TEXT_LINE1": "Wir benötigen Ihre Angaben, um Sie über den Termin zu informieren und Sie bei Bedarf zu kontaktieren. Sie erhalten im Anschluss eine Bestätigung des Termins per E-Mail und SMS.",
    "STEP2_INTRO_TEXT_LINE2": "Zudem erhalten Sie am Vortag des Termins eine automatische Erinnerung per SMS und E-Mail.",
    "STEP2_CONTACT_FORM": {
      "TXTCOMPANY": {
        "PLACEHOLDER": "Firma",
      },
      "TXTFIRSTNAME": {
        "PLACEHOLDER": "Name",
      },
      "TXTLASTTNAME": {
        "PLACEHOLDER": "Vorname",
      },
      "TXTPHONE": {
        "PLACEHOLDER": "Mobilnummer",
      },
      "TXTEMAIL": {
        "PLACEHOLDER": "E-Mail",
      },
      "INFO_TEXT": "Bei Änderungen werden die Daten nicht in Ihrem Benutzerprofil gespeichert.",
    },
    "STEP2_SUMMARY": {
      "TEXT_CONFIRM_WITH_SMS": "Bestätigung per SMS",
    },

    "STEP3_CONFIRM_CHK_LABEL_LINE1": "Bitte bestätigen Sie, dass Sie alle Angaben geprüft haben und die BKW diese Informationen für den Termin verwenden darf.",
    "STEP3_CONFIRM_CHK_LABEL_LINE2": "Vielen Dank.",

    "BTN_CONFIRM_APPOINTMENT_LABEL": "Absenden",

  },

  "VALIDATION_APPOINTMENT": {
    "EXPIRED_DATE": "Ihr persönlicher Zugangscode ist leider abgelaufen. Bitte melden Sie sich unter der Telefonnummer, die in Ihrem Avisierungsschreiben angegeben ist. Das Avisierungsschreiben ist das Schreiben, in dem die Information zu Ihrem Termin und Ihr persönlicher Zugangscode enthalten ist.",
    "NO_POSTPONEMENT_POSSIBLE": "Keine Terminverschiebung möglich!",
  },

  "DIALOG_PICK_SLOTS": {
    "TITLE": "Wählen Sie einen weiteren Termin.",
    "MAIN_TEXT": "Welchen Tag und welchen Zeitraum würden Sie bevorzugen?",
    "NO_DATES_AVAILABLE_TEXT": " An diesem Tag sind keine Termine verfügbar.",
    "WEEKDAY_LABEL": "Wochentag",
    "DAYPERIOD_LABEL": "Zeitraum",
    "NO_SLOTS_BTN_LABEL": "Kein passender Termin dabei",
    "CANCEL_BTN_LABEL": "Abbrechen",
    "CONFIRM_BTN_LABEL": "Speichern",
  },

  "PROBLEM_PAGE": {
    "TRYAGAIN_BTN_LABEL": "Nochmal versuchen",
    "ERROR__0": {
      "TITLE": "Ups! Ein technischer Fehler ist aufgetreten.",
      "MSG": "Zurzeit gibt es ein technisches Problem. Bitte kontaktieren Sie uns, um zusätzliche Unterstützung zu erhalten.",
    },
    "ERROR__5": {
      "TITLE": "Ups! Ein serverseitiger Fehler ist aufgetreten.",
      "MSG": "Es gibt im Moment ein Problem mit dem Remote-Server. Bitte versuchen Sie es in ein paar Minuten erneut oder kontaktieren Sie uns, um zusätzliche Unterstützung zu erhalten.",
    },
    "ERROR__10": {
      "TITLE": "Kein passender Termin dabei?",
      "MSG": "Kein Problem, wir finden einen geeigneten Termin für Sie. Rufen Sie uns einfach an.",
    },
    "ERROR__100002": {
      "TITLE": "Ups! Das ist der falsche Code.",
      "MSG": "Haben Sie sich vertippt? Versuchen Sie es ein zweites Mal. Falls der Code nicht funktioniert, melden Sie sich bei uns. Wir helfen Ihnen gerne weiter.",
    },
    "ERROR__57001": {
      "TITLE": "Ups! Dieser Code ist abgelaufen.",
      "MSG": "Die Frist zum Ändern des Termins ist abgelaufen. Falls Sie Fragen haben oder Hilfe benötigen, können Sie sich bei uns melden. Wir helfen Ihnen gerne weiter.",
    },
    "ERROR__400": {
      "TITLE": "Ups! Bei der Anfrage ist ein Fehler aufgetreten.",
      "MSG": "Es gibt im Moment ein Problem mit dem Remote-Server. Bitte versuchen Sie es in ein paar Minuten erneut oder kontaktieren Sie uns, um zusätzliche Unterstützung zu erhalten.",
    },
  },

  "THANK_PAGE": {
    "UNREGISTERED_USER": {
      "TITLE": "Besitzen Sie noch kein Kundenkonto?",
      "BTN_REGISTER_LABEL": "Jetzt registrieren",
    },
    "CONTACT_DATA_CHANGED": {
      "TITLE": "Sie haben Ihre Personaldaten geändert.",
      "TEXT": "Wollen Sie die angepassten Kontaktdaten auch für Ihr Benutzerkonto übernehmen?",
      "UPDATE_DATA_BTN_LABEL": "Im Benutzerkonto anpassen",
    },
    "THANK_YOU": "Vielen Dank!",
    "THANK_CONTENT_LINE1": "Ihre Angaben wurden erfolgreich übertragen.",
    "THANK_CONTENT_LINE2": "Sie erhalten die Bestätigung per ",
    "THANK_CONTENT_LINE2_STRONG": "SMS und E-Mail.",
  },
};
