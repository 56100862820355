export const FR_TRANSLATIONS = {
  "PREFIX_URL": "rendez-vousmonteur",
  "SERVICE_NAME": "Aperçu du programme",
  "IDENTIFY": {
    "TITLE": "Rendez-vous pour le remplacement du compteur",
    "TEXT": "Saisissez le code d'accès à 12 chiffres que vous avez reçu par courrier ou par e-mail pour traiter votre rendez-vous avec le monteur de compteurs. Veuillez faire attention aux majuscules et aux minuscules.",
    "IDENTIFY": "Débloquer",
    "CODE_REQUIRED": "Veuillez entrer un code valide",
    "PLACEHOLDER": {
      "CODE": "Code à 12 chiffres",
    },
  },
  "DATES": {
    "HOURS": "heure",

    "SUNDAY": "Dimanche",
    "MONDAY": "Lundi",
    "TUESDAY": "Mardi",
    "WEDNESDAY": "Mercredi",
    "THURSDAY": "Jeudi",
    "FRIDAY": "Vendredi",
    "SATURDAY": "Samedi",

    "JANUARY": "Janvier",
    "FEBRUARY": "Février",
    "MARCH": "Mars",
    "APRIL": "Avril",
    "MAY": "Mai",
    "JUNE": "Juin",
    "JULY": "Juillet",
    "AUGUST": "Août",
    "SEPTEMBER": "Septembre",
    "OCTOBER": "Octobre",
    "NOVEMBER": "Novembre",
    "DECEMBER": "Décembre",

    "MORNING": "Matin",
    "AFTERNOON": "Après-midi",
    "EVENING": "Soir",
  },

  "BKWCAB_MAIN": {
    "ERR_INVALID_AUTH": "Aucune donnée d’identification disponible",
    "ERR_NO_ORDER_INFOS": "Aucune date disponible",
    "BACK_TO_OVERVIEW": "Retour à l’aperçu du rendez-vous",
    "BACK_TO_DASHBOARD": "Retour à l’aperçu",
    "BACK_TO_DATES": "Retour à la sélection du rendez-vous",
    "BACK_TO_ENTERCODE": "Retour à la saisie du code",
    "NEW_APPOINTMENT_TITLE": "Rendez-vous pour le remplacement du compteur",
    "ORDER_NUMBER": "No. d'ordre",
    "PREPOSITION_TO": "jusqu’à",
    "LABEL_BTN_EDIT": "Modifier le rendez-vous",
    "LABEL_BTN_NEXT": "Suivant",
    "LABEL_BTN_PREVIOUS": "Précédent",
    "CONTACT_US_MESSAGE": "Veuillez nous contacter au numéro de téléphone indiqué dans votre lettre d’annonce. La lettre d’annonce est la lettre qui contient les informations relatives à votre rendez-vous et votre code d'accès personnel.",
    "REQUIRED_FIELD_MESSAGE": "Ce champ est obligatoire",
    "INVALID_PHONE_MESSAGE": "Ce numéro de téléphone n’est pas valable",
    "INVALID_EMAIL_MESSAGE": "L’adresse e-mail n’est pas valable",
  },

  "ELECTRIC_METER": {
    "INTRO_CONTENT": "Le monteur de compteur doit avoir accès à votre compteur électrique. Veuillez nous communiquer vos informations d'accès et de contact en utilisant l'option « Modifier le rendez-vous ».",
  },

  "STEPPER": {
    "STEP1_LABEL": "Informations d’accès",
    "STEP2_LABEL": "Vos coordonnées",
    "STEP3_LABEL": "Fin",
    "QUESTION1": {
      "LABEL": "Confirmez-vous le rendez-vous?",
      "RADIO1_CAPTION": "Oui, je confirme le rendez-vous.",
      "RADIO2_CAPTION": "Non, j’ai besoin d’un nouveau rendez-vous.",
    },
    "SLOT_CHOICE": {
      "PLACEHOLDER": "Sélectionnez la date souhaitée.",
      "LABEL_MORE_DATES": "Autres rendez-vous",
    },
    "QUESTION2": {
      "LABEL": "Comment pouvons-nous accéder au compteur électrique?",
      "RADIO_CAPTION_ACCESSTYPE__10": "Je me trouve sur place.",
      "RADIO_CAPTION_ACCESSTYPE__20": "La clé a été déposée.",
      "RADIO_CAPTION_ACCESSTYPE__30": "J’ai laissé la clé à quelqu’un.",
      "RADIO_CAPTION_ACCESSTYPE__40": "AUTRES",
      "SUGGESTED_COMMENT_ACCESSTYPE__10": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__20": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__30": " ",
      "SUGGESTED_COMMENT_ACCESSTYPE__40": " ",
    },
    "STEP1_NO_SLOTS_MSG": "Il est actuellement impossible de prendre un autre rendez-vous.",
    "ACCESS_GRANTED_BY_FORM": {
      "TEXT": "L’accès est accordé par:",
      "TXTFIRSTNAME": {
        "PLACEHOLDER": "Nom",
      },
      "TXTLASTTNAME": {
        "PLACEHOLDER": "Prénom",
      },
      "TXTPHONE": {
        "PLACEHOLDER": "Numéro de téléphone mobile",
      },
      "INFO_TEXT": "Le monteur appelle ce numéro.",
    },
    "TXTCOMMENTS": {
      "PLACEHOLDER_ACCESSTYPE__": "Commentaire pour le monteur",
      "PLACEHOLDER_ACCESSTYPE__10": "Commentaire pour le monteur",
      "PLACEHOLDER_ACCESSTYPE__20": "Merci de nous indiquer où la clé sera déposée. *",
      "PLACEHOLDER_ACCESSTYPE__30": "Commentaire pour le monteur *",
    },
    "STEP1_SUMMARY": {
      "TEXT_NEW_APPOINTMENT": "Nouveau rendez-vous",
    },

    "STEP2_INTRO_TEXT_LINE1": "Nous avons besoin de vos coordonnées afin de vous informer sur le rendez-vous et de vous contacter si nécessaire. Vous recevrez une confirmation du rendez-vous aussitôt après par e-mail ou SMS.",
    "STEP2_INTRO_TEXT_LINE2": "De plus, vous recevrez un rappel automatique par SMS et par e-mail la veille du rendez-vous.",
    "STEP2_CONTACT_FORM": {
      "TXTCOMPANY": {
        "PLACEHOLDER": "Entreprise",
      },
      "TXTFIRSTNAME": {
        "PLACEHOLDER": "Nom",
      },
      "TXTLASTTNAME": {
        "PLACEHOLDER": "Prénom",
      },
      "TXTPHONE": {
        "PLACEHOLDER": "Numéro de téléphone mobile",
      },
      "TXTEMAIL": {
        "PLACEHOLDER": "Adresse e-mail",
      },
      "INFO_TEXT": "En cas de modification, les données ne seront pas enregistrées dans votre profil utilisateur.",
    },
    "STEP2_SUMMARY": {
      "TEXT_CONFIRM_WITH_SMS": "Confirmation par SMS",
    },
    "STEP3_CONFIRM_CHK_LABEL_LINE1": "Veuillez confirmer que vous avez vérifié toutes les données et que BKW peut utiliser ces informations pour le rendez-vous.",
    "STEP3_CONFIRM_CHK_LABEL_LINE2": "Merci beaucoup.",

    "BTN_CONFIRM_APPOINTMENT_LABEL": "Envoyer",

  },

  "VALIDATION_APPOINTMENT": {
    "EXPIRED_DATE": "Votre code d'accès personnel a malheureusement expiré. Veuillez nous contacter au numéro de téléphone indiqué dans votre lettre d’annonce. La lettre d’annonce est la lettre qui contient les informations relatives à votre rendez-vous et votre code d'accès personnel.",
    "NO_POSTPONEMENT_POSSIBLE": "Aucun changement de date possible!",
  },

  "DIALOG_PICK_SLOTS": {
    "TITLE": "Sélectionnez un autre rendez-vous.",
    "MAIN_TEXT": "Quel jour et quelle plage horaire vous conviendraient le mieux?",
    "NO_DATES_AVAILABLE_TEXT": " An diesem Tag sind keine Termine verfügbar.",
    "WEEKDAY_LABEL": "Jour de la semaine",
    "DAYPERIOD_LABEL": "Plage horaire",
    "NO_SLOTS_BTN_LABEL": "Aucun rendez-vous adapté disponible",
    "CANCEL_BTN_LABEL": "Annuler",
    "CONFIRM_BTN_LABEL": "Enregistrer",
  },

  "PROBLEM_PAGE": {
    "TRYAGAIN_BTN_LABEL": "Réessayer",
    "ERROR__0": {
      "TITLE": "Oups! Une erreur technique est survenue.",
      "MSG": "Nous rencontrons actuellement un problème technique. Veuillez nous contacter pour obtenir une assistance supplémentaire.",
    },
    "ERROR__5": {
      "TITLE": "Oups! Une erreur de serveur est survenue.",
      "MSG": "Le serveur à distance rencontre actuellement un problème. Veuillez renouveler votre tentative dans quelques minutes ou nous contacter pour obtenir une assistance supplémentaire.",
    },
    "ERROR__10": {
      "TITLE": "Aucun rendez-vous adapté n’est disponible?",
      "MSG": "Aucun problème, nous allons trouver un rendez-vous qui vous convient. Il vous suffit pour cela de nous téléphoner.",
    },
    "ERROR__100002": {
      "TITLE": "Oups! Le code est incorrect.",
      "MSG": "Vous avez peut-être fait une faute de frappe? Essayez de nouveau. Si le code ne fonctionne pas, contactez-nous. Nous sommes à votre disposition.",
    },
    "ERROR__57001": {
      "TITLE": "Ups! Ce code a expiré.",
      "MSG": "Le délai de modification du rendez-vous a expiré. N’hésitez pas à nous contacter si vous avez des questions ou si vous avez besoin d’aide. Nous sommes à votre disposition.",
    },
    "ERROR__400": {
      "TITLE": "Oups! Une erreur d'equete est survenue.",
      "MSG": "Le serveur à distance rencontre actuellement un problème. Veuillez renouveler votre tentative dans quelques minutes ou nous contacter pour obtenir une assistance supplémentaire.",
    },
  },

  "THANK_PAGE": {
    "UNREGISTERED_USER": {
      "TITLE": "Vous n’avez pas encore de compte client?",
      "BTN_REGISTER_LABEL": "Inscrivez-vous dès maintenant",
    },
    "CONTACT_DATA_CHANGED": {
      "TITLE": "Vous avez modifié vos données personnelles.",
      "TEXT": "Souhaitez-vous que la modification apportée aux données de contact s’applique également à votre compte utilisateur?",
      "UPDATE_DATA_BTN_LABEL": "Modifier votre compte utilisateur",
    },
    "THANK_YOU": "Merci beaucoup!",
    "THANK_CONTENT_LINE1": "Vos données ont été transmises avec succès.",
    "THANK_CONTENT_LINE2": "Vous recevrez une confirmation par ",
    "THANK_CONTENT_LINE2_STRONG": "SMS et e-mail.",
  },
};
