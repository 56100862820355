import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Params } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class AppointmentAuthGuard implements CanActivate {

  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isAuthenticated: boolean = this.bfcAuthenticationService.authenticated;
    const hasCode: boolean = AppointmentAuthGuard.hasCodeInQueryParams(route.queryParams);

    if (isAuthenticated){
      return isAuthenticated;
    }
    const redirectToIdentify = !isAuthenticated && !hasCode;
    if (redirectToIdentify) {
      this.bfcAuthenticationService.login();
    }
    return !redirectToIdentify;
  }

  private static hasCodeInQueryParams(params: Params): boolean{
    const isNotUndefined = params.code !== undefined;
    const hasCorrectLength = params.code ? params.code.length === 14 : false;

    return isNotUndefined && hasCorrectLength;
  }
}
