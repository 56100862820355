import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import { ErrorResponse } from "../../appointments/model/ErrorResponse";
import { CabErrorType } from "../../appointments/model/CabErrorType";

const GENERIC_SERVER_ERROR_CODE = 500;
const BAD_REQUEST_ERROR_CODE = 400;

@Injectable()
export class WfmErrorHandler implements WfmErrorHandler {

  public currentError$ = new Subject<ErrorResponse>();

  handleError<T>(result?: T) {
    return (error: HttpErrorResponse): Observable<T> => {
      let currentError: ErrorResponse = WfmErrorHandler.checkErrorStatus(error);
      this.currentError$.next(currentError);
      // Let the app keep running by returning an empty result.
      return throwError(result as T);
    };
  }

  private static checkErrorStatus(error: HttpErrorResponse): ErrorResponse{
    let currentError: ErrorResponse;
    if (error.error) {
      switch (error.error.errorCode) {
        case CabErrorType.NO_SLOT_AVAILABLE:
          currentError = { errorCode: CabErrorType.NO_SLOT_AVAILABLE };
          break;
        case CabErrorType.MLOG_TOKEN_NOT_FOUND:
          currentError = { errorCode: CabErrorType.MLOG_TOKEN_NOT_FOUND };
          break;
        case CabErrorType.MLOG_TOKEN_EXPIRED:
          currentError = { errorCode: CabErrorType.MLOG_TOKEN_EXPIRED };
          break;
        default:
          currentError = { errorCode: CabErrorType.GENERIC };
      }
    } else if (error.status === GENERIC_SERVER_ERROR_CODE){
      currentError = { errorCode: CabErrorType.GENERIC_SERVER };
    } else if (error.status === BAD_REQUEST_ERROR_CODE){
      if (error.error.errorCode === CabErrorType.MLOG_TOKEN_EXPIRED){
        currentError = { errorCode: CabErrorType.MLOG_TOKEN_EXPIRED };
      } else {
        currentError = { errorCode: CabErrorType.BAD_REQUEST };
      }
    }
    return currentError;
  }
}
