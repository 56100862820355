import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { InitializerProvider } from "./initialization/initializer-provider";
import { initializerFactory } from "./initialization/initializer-factory";
import { WfmErrorHandler } from "./error/wfm-error-handler";
import { IdentifyAuthGuard } from "./identify.auth-guard";
import { AppointmentAuthGuard } from "./appointment.auth-guard";

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    WfmErrorHandler,
    IdentifyAuthGuard,
    AppointmentAuthGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
